.Panels {
  .MuiGrid-root {
    position: relative;
    @apply py-4 px-8;
    & + .MuiGrid-root {
      &::before {
        content: '';
        display: block;
        width: 1px;
        height: 100%;
        background-color: #e0e0e0;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
}

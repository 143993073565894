html,
body {
  margin: 0px;
  padding: 0px;
  background-color: #eee;
}

iframe {
  pointer-events: none;
}

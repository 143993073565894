.FormController {
  .MuiGrid-container {
    display: flex;
    .MuiGrid-container {
      padding-top: 16px;
      margin-left: 0;
    }
    .MuiGrid-item {
      min-width: 50%;
    }
  }
  .MuiFormLabel-root:not(.MuiFormLabel-filled) + .MuiInputBase-root {
    .MuiInputAdornment-root {
      display: none
    }
  }
  .actions {
    @apply mt-4;
    .MuiButtonBase-root {
      & + .MuiButtonBase-root {
        @apply ml-4;
      }
    }
  }
}

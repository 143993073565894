.PalowansWithDaysToExpireCard {
  .MuiTypography-caption {
    @apply m-auto table mb-4;
  }
  .avatar-wrapper {
    margin: auto;
    max-width: 500px;
    .MuiAvatar-root {
      background-color: #e6faf5;
      color: #01291e;
    }
  }
}

.MuiInputBase-root.MuiOutlinedInput-root {
    @apply rounded-xl;
    &.Mui-focused .MuiOutlinedInput-notchedOutline {
        @apply border-gray-600;
    }
    .MuiOutlinedInput-input {
        @apply text-base;
    }
    &.MuiInputBase-sizeSmall .MuiOutlinedInput-input {
        @apply p-3;
    }
    &.MuiInputBase-multiline {
      textarea {
        min-height: 100px;
        max-height: 100px;
        overflow: auto !important
      }
    }
}
.MuiInputLabel-root.Mui-focused {
    @apply text-gray-600 #{!important};
}

.LeavesBalance {
  .InfoHeader {
    margin-top: -10px;
    margin-right: -27px;
  }
  .MuiTypography-root.MuiTypography-h5 {
    @apply mr-4;
    min-width: 32px;
    text-align: center;
  }
  .help-text {
    .MuiTypography-body1 {
      @apply mb-4;
    }
  }
}

.MuiChip-root.Status {
  @apply rounded cursor-default;
  &.MuiChip-colorWarning {
    background-color: #FDF2EB;
  }
  &.MuiChip-colorInfo {
    background-color: #ECF0FD;
  }
  &.MuiChip-colorSuccess {
    background-color: #E6FAF5;
  }
  &.MuiChip-colorError {
    background-color: #FDE7E9;
  }
  &.MuiChip-colorDefault {
    opacity: .6;
  }
}

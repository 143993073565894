.Menu {
  .Logo {
    display: initial;
  }
  .pathname {
    display: none;
  }
  .MuiTabs-root {
    @apply flex-1;
    .MuiTabs-scroller {
      @apply flex justify-end;
    }
  }
  @media (max-width: 768px) {
    .Logo {
      display: none;
    }
    .pathname {
      display: initial;
    } 
  }
}

.BlankCard {
  .MuiCardContent-root {
    @apply p-4;
    & > .MuiTypography-root.MuiTypography-h5 {
      @apply mb-4;
    }
    & > .MuiTypography-root.MuiTypography-body1 {
      @apply mb-4;
    }
    .MuiTypography-root.MuiTypography-h6 {
      @apply my-3;
    }
  }
  .actions {
    margin-bottom: -16px;
  }
}

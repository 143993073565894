.approved {
  position: relative;
}
.approved::before {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: rgba(0, 192, 128, var(--tw-text-opacity));
  display: table;
  position: absolute;
  left: -15px;
  top: 50%;
  transform: translateY(-50%);
}

.Modal {
  .BlankCard {
    max-width: 80vw;
    min-width: 500px;
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
  }
  &:not(.small) {
    .BlankCard {
      min-height: 500px;
    }
  }
  .MuiButton-root {
    & + .MuiButton-root {
      @apply ml-4;
    }
  }
  &.small .MuiPaper-root {
    max-width: 500px;
  }
}

.ImagePreviewModal {
  .MuiCardContent-root {
    & > div {
      display: flex;
    }
  }
  img {
    max-height: 80vh;
  }
}

.TabBar {
  display: none;
}
.Layout .Container {
  padding-top: 195px;
  max-height: 100vh;
}
@media (max-width: 768px) {
  .NavBar {
    display: none;
  }
  .TabBar {
    display: initial;
  }
  .Banner {
    display: none;
  }
}

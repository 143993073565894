.StickyHeadTable {
  .MuiTableCell-head {
    @apply bg-gray-200;
  }
  .placeholder {
    @apply bg-white flex items-center justify-center flex-col p-10;
    min-height: calc(100vh - 520px);
  }
  .MuiTablePagination-root {
    @apply bg-purple-50;
  }
}
